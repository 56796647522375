@import '@styles/styles.scss';

.resultedWrapperThreeHoldConfirming {
    .mainInfosWrapper {
        flex-direction: column;
    }
    .orderInfos {
        margin-top: 0;
    }

    .btnGroupWrapper {
        display: flex;
        justify-content: center;
    }
}
