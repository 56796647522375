@import '@styles/styles.scss';

.confirmInfos {
    display: flex;
    justify-content: center;

    @include mobile {
        justify-content: flex-start;
    }
    .infosTitle {
        display: none;
    }
}
