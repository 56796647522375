@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        margin-bottom: 10px;

        .headline {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .mainContent {
            margin-top: 25px;
        }
    }

    .dialogActionGroup {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 40px;

        .btn {
            width: 60%;
            & + .btn {
                margin: 0 auto 20px;
            }
        }
    }
}
