@import '@styles/styles.scss';

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.mainInfosWrapper {
    display: flex;
    padding-top: 0;

    box-shadow: 0 2px 10px 4px rgba(37, 39, 38, 0.05);

    .infosTitle {
        font-size: $FONTSIZE_20;
        color: $primaryColor;
    }

    @include tablet {
        flex-direction: column;
    }
}

/*=============================================
=                 Buttons                     =
=============================================*/
.MuiOutlinedInput-root.inputField,
.react-tel-input .inputField {
    border-radius: 0;
}

.timer p {
    border-radius: 0;
}

.btnGroupWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    @include desktop {
        padding: 20px 0px 20px;
    }

    @include mobile {
        margin-top: 0;
    }

    .btn {
        max-width: 560px;
        width: 40%;
        flex: 1;
    }

    @include dividerTop(100%);
    &:after {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);
    }
}

.btn {
    .content {
        .iconAddition {
            display: none;
        }
    }

    @include mobileSmall {
        text-align: center;
    }

    // &.MuiButton-root,
    // &.MuiButton-contained,
    // &.MuiButton-outlined {
    // }

    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
            stroke: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $primaryColor;
            color: $primaryTextContrast;
            opacity: 0.75;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        color: $primaryColor;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $primaryColor;

        &:hover {
            border-color: $primaryColor;
            box-shadow: none;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.04);
        }

        .MuiButton-label {
            color: $COLOR_STEELGREY;
        }

        .text {
            color: $primaryColor;
        }
    }
}

/*=====  End of Buttons  ======*/
.itemField.count,
.itemField.userPrice {
    display: none;
}
.itemField {
    &.unitCount,
    &.realAmount {
        .unit,
        .numeric {
            color: $primaryColor;
            line-height: 1;
        }
    }
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $primaryColor;
        color: $primaryTextContrast;
        border: 1px solid $COLOR_MIDGREY;

        .MuiAlert-icon {
            color: $primaryTextContrast;
        }
    }
}

.iconTitle,
.iconHead {
    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.15));
}

.inputFieldHelperTxt {
    &.MuiFormHelperText-root {
        color: $COLOR_FALU_Red;
    }
}
