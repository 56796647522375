@import '@styles/styles.scss';

.orderInfos {
    background-color: transparent;
    padding: 30px;

    .infosTitle {
        font-size: $FONTSIZE_24;
        font-weight: bold;
        margin-bottom: 24px;
    }

    .infosWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .rightInfos .fieldLabelWrapper {
            min-width: 20px;
        }

        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }

    &.smsVerificationInfosWrapper,
    &.recipientInfos {
        background-color: $infosBkColor;

        @include desktop {
            flex: 2;
        }
    }

    @include mobile {
        padding: 30px 20px;
    }
}
