@import '@styles/styles.scss';

.itemTag {
    margin-top: 5px;
    button {
        background-color: transparent;
        border-radius: 32px;
        border: 1px solid $COLOR_MIDNIGHT_BLUE;
        box-shadow: none;
        color: $COLOR_MIDNIGHT_BLUE;

        &:hover {
            background-color: transparent;
        }
        .iconTick {
            width: 15px;
        }
    }
}
