@import '@styles/styles.scss';

.ItemPayMethodOption {
    height: 65px;
    width: 91px;
    border: 2px solid $primaryColor;
    background-color: transparent;

    .payMethodName {
        display: none;
    }
}
