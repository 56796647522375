@import '@styles/styles.scss';

.wrapper {
    .companyInfosWrapper {
        display: none;
    }
    &.sellPaying,
    &.buyPaying {
        .infosGroupWrapper {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            position: relative;

            @include desktop {
                flex: 1;
            }
        }

        @include tablet {
            position: relative;
            .infosGroupWrapper {
                width: auto;
            }
        }
    }

    &.sellPayingTwoStepSimple,
    &.buyConfirming,
    &.resultedWrapper {
        .mainInfosWrapper {
            background-color: $infosBkColor;
            flex-direction: column;
        }
    }

    &.buyPayingManual {
        .mainInfosWrapper {
            background-color: $infosBkColor;
            justify-content: center;
        }
    }
}
