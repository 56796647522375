@import '@styles/styles.scss';

.stepper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    @include dividerBottom(100%);

    &:after {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .logoBrand {
        margin-bottom: 13px;
        margin-right: 20px;
    }
    .stepsWrapper {
        display: flex;
        justify-content: flex-end;
        max-width: 1200px;
        width: 100%;
        margin: auto;

        @include tablet {
            justify-content: space-evenly;

            margin: 0 20px;
        }
    }
    .stepItm {
        min-width: 251px;
        min-height: 79px;
        padding: 0 10px;
        color: #d1d1d1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 2px;

        .stepText {
            display: flex;
            justify-content: center;
            align-items: center;
            .stepIndicator {
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $FONTSIZE_16;
                border-radius: 50%;
                background-color: $COLOR_MIDGREY;
                box-shadow: $primaryShadow;
                color: $COLOR_WHITE;

                .stepIndex {
                    margin-bottom: 5px;
                }
            }
            .stepName {
                font-size: $FONTSIZE_20;
                margin-left: 10px;
            }
        }

        &.currStep,
        &.completedStep {
            color: $COLOR_BLACK;
            .stepIndicator {
                color: $primaryTextContrast;
                background-color: $primaryColor;
            }
            .iconTick {
                .fillArea {
                    fill: $primaryTextContrast;
                    stroke: $primaryTextContrast;
                }
            }
        }
    }

    @include desktop {
        .stepItm {
            padding-top: 15px;
        }
    }

    @include tablet {
        .stepItm {
            min-width: 117px;
            min-height: 43px;
            padding: 0 15px;

            .stepText {
                align-items: center;
                .stepIndicator {
                    width: 22px;
                    height: 22px;
                    min-width: 22px;
                    min-height: 22px;
                    font-size: $FONTSIZE_14;

                    .stepIndex {
                        margin-bottom: 2px;
                    }
                }
                .stepName {
                    font-size: $FONTSIZE_16;
                }
            }

            .iconTick {
                width: 12px;
            }
        }
    }

    @include mobileLarge {
        .stepsWrapper {
            width: 100%;
            min-height: 44px;
        }

        .stepItm {
            width: 100%;
            min-width: auto;
            min-height: auto;
            padding: 0;
            padding-left: 10px;
            border-radius: 20px 20px 0 0;

            .stepText {
                align-items: center;
                .stepIndicator {
                    font-size: $FONTSIZE_BODY;
                    margin-bottom: 0px;

                    .stepIndex {
                        margin-bottom: 0;
                    }
                }
                .stepName {
                    margin-bottom: 5px;
                    font-size: $FONTSIZE_14;
                }
            }
        }
    }
    @include mobile {
        .stepItm {
            padding-left: 5px;
            justify-content: center;
            align-items: flex-start;
            .stepText {
                flex-direction: column;
                .stepIndicator {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    min-height: 16px;
                }

                .stepName {
                    margin-top: 10px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
        }
    }

    @include mobileSmall {
        .stepItm {
            .stepText {
                .stepName {
                    font-size: 10px;
                    margin-left: 4px;
                }
            }
        }
    }
}
