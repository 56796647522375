@import '@styles/styles.scss';

.infosHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: relative;
    padding-bottom: 20px;
    padding-top: 70px;

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .title {
            margin-top: 20px;
            font-size: $FONTSIZE_24;
            text-align: center;
        }

        .iconHead {
            font-size: 50px;
            margin-right: 4px;
        }

        .tickIco,
        .processIco,
        .cancelIco,
        .waitingIco {
            .fillArea {
                fill: $primaryColor;
                stroke: $primaryColor;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_18;
        margin: 0 30px;
        text-align: center;
    }

    @include tablet {
        padding-top: 50px;
    }
}
