@import '@styles/variables.scss';

/*=============================================
=               Template Color                =
=============================================*/
$primaryColor: $COLOR_RIBBON_BLUE;
$infosBkColor: $COLOR_ZIRCON_BLUE;
$infosBkColorSecondary: $COLOR_WHITE;
$primaryTextContrast: $COLOR_WHITE;
$bodyBkColor: $COLOR_WHITE;

$primaryShadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
/*=====  End of Template Colors  ======*/
